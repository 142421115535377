<template>
  <div class="auth-page__holder">
    <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link class="main-nav__logo-holder-mobile"
                       area-label="logo"
                       to="/"><logo color="dark"/></router-link>
        </div>
      </div>
    </header>
    
    <div class="container">
      <h1 class="text--center">404 – Page Not Found</h1>
    </div>
 </div>
</template>

<script>
  import Logo from '@/components/logo/Logo.vue';
  
  export default {
    name: 'not-found',
    components: { Logo },
  }
</script>

<style lang="scss">

</style>
