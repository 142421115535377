<template>
  <div>
    <div class="text">
      Bitte akzeptieren Sie unseren neuen Auftragsdatenverarbeitungsvertrag (AVV). Sollten Sie dies ablehnen ist es leider nicht möglich unsere Präventionstrainings zu nutzen. Für Rückfragen stehen wir Ihnen jederzeit gerne per Mail an <a href="mailto:info@cyberdirekt.de" class="notif-link">info@cyberdirekt.de</a> oder telefonisch unter <a href="tel:03040366036" class="notif-link">030/40366036</a> zur Verfügung.
    </div>
    <div class="actions">
      <button class="btn btn--primary" @click="openAvv">Jetzt durchlesen</button>
      <button class="btn btn--default" @click="cancel">Abmelden</button>
    </div>
  </div>
</template>

<script>
export default { 
  methods: {
    openAvv() {
      this.$store.commit('OPEN_AVV_MODAL')
    },
    cancel() {
      this.$store.dispatch('LOG_OUT')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
