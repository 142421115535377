import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {getSession, setSession, clearSession} from '@/services/session';
import {isInsuranceNumberValid} from '@/validators';
import {get as getValue, find} from 'lodash';
import i18n from '@/i18n';
import { loadLanguageAsync } from '@/i18n';
import router from '@/router';

Vue.use(Vuex);
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
axios.defaults.headers.get['If-Modified-Since'] = '0';

axios.defaults.baseURL = '/api/';
Vue.http = Vue.prototype.$http = axios;


export default new Vuex.Store({
  state: {
    users: [],
    usersPendingProcess: false,
    employeesAndManagers: [],
    $routeFrom: null,
    selectedUser: null,
    selectedUserToDelete: null,
    selectedUserToResetCompany: null,
    courses: [],
    courseProgresses: null,
    selectedCourse: null,
    selectedCourseProgress: null,
    companyProgress: null,
    companyStatistic: null,
    statisticData: {},
    avvRegCheckboxAdded: new Date('2022-03-18T10:38:00'),
    account: {
      user: {
        firstname: '',
        lastname: '',
        email: '',
        salutation: '',
        role: '',
        id: '',
        active: false,
        isFirstLogin: true,
        companyId: '',
        dataProtectionPolicyAccepted: false,
        lang: null,
      },
      company: {
        companyName: '',
        address: '',
        phoneNumber: '',
        insuranceNumber: '',
        employeeCount: null,
        id: '',
        ownerId: '',
      },
      isAvvModal: false,
      notifications: [],
      actionsBlocked: false,
    },
    session: getSession(),
  },
  getters: {
    language: state => state.account.user.lang || 'de',
    token: state => state.session.token,
    logged: state => !!state.session.token && !!state.session.userId,
    role: state => state.session.role || state.account.user.role,
    user: (state, getters) => state.account.user,
    users: (state, getters) => state.users.map((user, index) => {
      user.display_id = index + 1
      user.companyName = user.ownsOneCompany ? user.ownsOneCompany.companyName : ''
      return user
    }),
    employeesAndManagers: (state, getters) => state.employeesAndManagers,
    courses: (state, getters) => state.courses,
    getCourseProgresses: (state, getters) => state.courseProgresses,
    isAdmin: (state, getters) => getters.role === 'admin',
    isEmployer: (state, getters) => getters.role === 'employer',
    isManager: (state, getters) => getters.role === 'manager',
    isEmployee: (state, getters) => getters.role === 'employee',
    isFirstLogin: state => state.account.user.isFirstLogin,
    isSettingsDataFullFilled: ({account: {user, company}}) => {
      return !!(user.firstname && user.lastname && user.email && user.salutation && company.companyName &&
        company.address && company.phoneNumber && company.insuranceNumber && isInsuranceNumberValid(company.insuranceNumber));
    },
    dataProtectionPolicyAccepted: state => state.account.user.dataProtectionPolicyAccepted,
    shouldShowHelpVideo(state, getters) {
      return ['employer', 'manager'].indexOf(getters.role) > -1 && !state.account.user.sawVideo;
    },
    isAvvNotification(state) {
      const conditions =
        (state.account.user.createdAt < state.avvRegCheckboxAdded) &&
        (state.account.user.role === 'employer') &&
        (!state.account.user.orderProcessingAccepted)
      // const conditions = true // CHANGE ME, hardcode
      return conditions ? true : false
    },
    isActionsBlocked(state) {
      // const isAffectedRole = state.account.user.role === 'employee'|| state.account.user.role === 'manager'
      return state.account.actionsBlocked;
    },
    accountCompany(state) {
       return state.account.company
    }
  },
  mutations: {
    SET_AVV_BLOCK: (state, boo) => {
      state.account.actionsBlocked = boo;
    },
    SET_LANGUAGE: (state, lang) => {
      state.account.user.lang = lang;
    },
    SET_ROUTE_FROM: (state, payload) => {
      state.$routeFrom = payload;
    },
    SET_USERS_PENDING: (state, bool) => {
      state.usersPendingProcess = bool;
    },
    SET_USERS: (state, payload) => {
      state.users = payload;
    },
    MERGE_USERS: (state, payload) => {
      state.users = [...state.users, ...payload];
    },
    SET_EMPLOYEES_AND_MANAGERS: (state, payload) => {
      state.employeesAndManagers = payload;
    },
    ADD_OWNER: (state, payload) => {
      const alreadyExist = state.users.find( u => u.email === payload.email)
      if (alreadyExist) return
      state.users = [payload, ...state.users]
    },
    SET_SESSION_DATA: (state, payload) => {
      state.session = payload;

      setSession(payload);
    },
    CLEAR_SESSION_DATA: (state) => {
      state.session = {
        token: null,
        userId: null,
        ttl: null,
        created: null,
        role: null,
      };

      clearSession();
    },
    START_INVITATION_USER: (state) => {
      state.selectedUser = {
        firstname: '',
        lastname: '',
        email: '',
        role: 'employee',
        active: false,
        salutation: '',
        assignedCourseList: []
      };
    },
    START_CREATING_ADMIN: (state) => {
      state.selectedUser = {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      };
    },
    START_UPDATE_USER: (state, user) => {
      state.selectedUser = user;
    },
    SET_USER_DETAILS: (state, userDetails) => {
      state.selectedUser = userDetails;
    },
    START_DELETE_USER: (state, user) => {
      state.selectedUserToDelete = user;
    },
    START_RESET_COMAPNY: (state, user) => {
      state.selectedUserToResetCompany = user;
    },
    UPDATE_USER: (state, updatedUser) => {
      state.users = state.users.map(user => {
        if (user.id === updatedUser.id) return updatedUser;

        return user;
      })
    },
    CLEAR_SELECTED_USER(state) {
      state.selectedUser = null;
      state.selectedUserToDelete = null;
    },
    CLEAR_SELECTED_USER_TO_RESET_COMPANY(state) {
      state.selectedUserToResetCompany = null;
    },
    SAVE_INVITED_USER: (state, payload) => {
      const d = new Date();
      payload.createdAt = d.toISOString();
      state.users.unshift(payload);

    },
    DELETE_USER: (state, userId) => {
      state.users = state.users.filter(user => user.id !== userId);
    },
    UPDATE_USER_ACCOUNT_DATA: (state, userData) => {
      state.account.user = {...state.account.user, ...userData};
    },
    UPDATE_COMPANY_ACCOUNT_DATA: (state, companyData) => {
      state.account.company = {...state.account.company, ...companyData};
    },
    CLEAR_ACCOUNT_DATA: (state) => {
      state.account.user = {
        firstname: '',
        lastname: '',
        email: '',
        salutation: '',
        role: '',
        id: '',
        active: false,
        isFirstLogin: true,
        companyId: '',
      };

      state.account.company = {
        companyName: '',
        address: '',
        employeeCount: null,
        id: '',
        ownerId: '',
      };
    },
    SET_COURSES: (state, coursesData) => {
      state.courses = coursesData;
    },
    UPDATE_COURSE: (state, {courseId, employeeIDs}) => {
      const course = find(state.courses, ['id', courseId]);

      course.employees = employeeIDs;

      state.courses = [...state.courses];
    },
    START_ASSIGNING_EMPLOYEES_TO_THE_COURSE: (state, course) => {
      state.selectedCourse = course;
    },
    SELECT_COURSE: (state, courseData) => {
      state.selectedCourse = courseData;
    },
    SET_COURSE_PROGRESSES: (state, courseProgresses) => {
      state.courseProgresses = courseProgresses;
    },
    SET_COURSE_PROGRESS: (state, courseProgress) => {
      state.selectedCourseProgress = courseProgress;
    },
    UPDATE_COURSE_PROGRESS: (state, courseProgressPatch) => {
      state.selectedCourseProgress = {
        ...state.selectedCourseProgress,
        ...courseProgressPatch,
      };
    },
    INCREASE_QUIZ_REPEAT_COUNT: (state) => {
      state.selectedCourseProgress.quizRepeatCount += 1;
    },
    SET_COMPANY_PROGRESS: (state, companyProgress) => {
      state.companyProgress = companyProgress;
    },
    SET_COMPANY_STATISTIC: (state, companyStatistic) => {
      state.companyStatistic = companyStatistic;
    },
    CLEAR_SELECTED_COURSE: (state) => {
      state.selectedCourse = null;
    },
    OPEN_AVV_MODAL(state) {
      state.account.isAvvModal = true
    },
    CLOSE_AVV_MODAL(state) {
      state.account.isAvvModal = false
    },
    ADD_NOTIFICATIONS(state, notification) {
      state.account.notifications = notification
    },
    REMOVE_NOTIFICATION(state, name) {
      const idx = state.account.notifications.findIndex(oneNotif => {oneNotif.name === name})
      state.account.notifications.splice(idx, i)
    }
  },
  actions: {
    async SEND_EMAIL_TO_EMPLOYER({state}, user) {
      try {
        const payload = { email: user.email}
        if (user.cc) {
          payload.cc = user.cc
        }
  
        await Vue.http.post('/pdf/send-upload-confirmation', payload);
        return { success: true, message: `${ i18n.t('users.email_sent')} ${user.email}` + (user.cc ? `, ${user.cc}` : '')}
        
      } catch (e) {
        return { success: false, message: i18n.t('users.email_not_sent') }
      }
    },
    SHOW_VIDEO({state, commit}) {
      commit('UPDATE_USER_ACCOUNT_DATA', {sawVideo: false});
    },
    SET_VIDEO_SHOWN: async ({state, commit}) => {
      const {userId} = state.session;

      try {
        const {data: userData} = await Vue.http.patch(`/employee/${userId}`, {sawVideo: true});

        commit('UPDATE_USER_ACCOUNT_DATA', userData);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    DATA_PROTECTION_POLICY_ACCEPT: async ({state, commit}) => {
      const {userId} = state.session;

      try {
        const {data: userData} = await Vue.http.patch(`/employee/${userId}`, {dataProtectionPolicyAccepted: true});

        commit('UPDATE_USER_ACCOUNT_DATA', userData);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    REGISTER: async (state, {email, firstname, lastname, companyName, password}) => {
      try {
        await Vue.http.post('/employee/create-employer/', {email, firstname, lastname, companyName, password});

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    RESEND_INVITATION: async (state, userId) => {
      try {
        await Vue.http.get(`/employee/resend-invitation?employeeId=${userId}`);

        return {success: true};
      } catch (error) {
        // console.log(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    PASSWORD_RESET_REQUEST: async (state, email) => {
      try {
        await Vue.http.post('/employee/reset', {email});

        return {success: true};
      } catch (error) {
        // console.log(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    PASSWORD_RESET_REQUEST_FOR_ADMIN: async (state, email) => {
      try {
        await Vue.http.post('/appuser/reset', {email});

        return {success: true};
      } catch (error) {
        // console.log(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    INIT_USER_DATA: async ({state, commit, dispatch}, isForce) => {
      if (!isForce && state.account.user.id) return Promise.resolve();

      const {userId} = state.session;

      try {
        const {data: userData} = await Vue.http.get(`/employee/${userId}`);

        // here set the lingo based on users prefs
        const userLang = userData.lang ? userData.lang : 'de';

        if (userData.role === 'manager' || userData.role === 'employee') {
          
          const {data: supervisorData} = await Vue.http.post(`/employee/supervisor-data/${userData.id}`)
          .catch(e => {
            commit('SET_AVV_BLOCK', true);
            console.error('error with getting supervisor data', e)
          });

          commit('SET_AVV_BLOCK', !supervisorData.hasOrderProcessing);
        }

        commit('UPDATE_USER_ACCOUNT_DATA', userData);
        dispatch('CHANGE_LANGUAGE', userLang);

        if (userData.role === 'employer') {
          const {data: companyData} = await Vue.http.get(`/employee/${userId}/ownsOneCompany`);
          commit('UPDATE_COMPANY_ACCOUNT_DATA', companyData);
        } else {
          const {data: companyData} = await Vue.http.get(`/company/${userData.companyId}`);
          commit('UPDATE_COMPANY_ACCOUNT_DATA', companyData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    GET_USERS: async ({state, commit}) => {
      const {id} = state.account.company;

      try {
        const {data: users} = await Vue.http.get(`/company/${id}/employees`);

        commit('SET_USERS', users);

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_EMPLOYERS: async ({commit}) => {
      const maxItemsSize = 1000

      try {

        const {data: data} = await Vue.http.get('/employee/count?where={"role":"employer"}');
        if (data.count > maxItemsSize) {
          const totalCount = data.count
          let itemsLeft = totalCount

          // get by chunks
          async function getChunk() {            
            const query = {
              where: {
                "role":"employer"
              },
              include:"ownsOneCompany",
              limit: maxItemsSize,
              skip: totalCount - itemsLeft
            }

            let isFirstChunk = false
            if (itemsLeft === totalCount) {
              isFirstChunk = true
            }
            const {data: employers} = await Vue.http.get(`/employee?filter=${JSON.stringify(query)}`);
            itemsLeft = itemsLeft - employers.length
            
            if (isFirstChunk) {
              commit('SET_USERS_PENDING' , true)
              commit('SET_USERS', employers)
            } else {
              commit('MERGE_USERS', employers)
            }

            if (itemsLeft > 0) {
              getChunk()
            } else if (itemsLeft < 1) {
              commit('SET_USERS_PENDING' , false)
            }
          }

          getChunk()
  
          return {success: true};
          
        } else {
          // get all with 1 request
          try {
            const {data: employers} = await Vue.http.get('/employee?filter={"where":{"role":"employer"},"include":"ownsOneCompany"}');
    
            commit('SET_USERS', employers);
    
            return {success: true};
          } catch (error) {
            // console.dir(error);
    
            const {message} = getValue(error, 'response.data.error', error);
    
            return {success: false, message};
          }
        }
      } catch(e) {
        console.error(e)
      }


    },
    GET_EMPLOYEES_AND_MANAGERS: async ({commit}, {companyId}) => {
      let employeesAndManagers
      
      try {
        const {data} = await Vue.http.get(`/employee?filter={"where":{"and": [{"or":[{"role":"manager"}, {"role": "employee"}]}, {"companyId": "${companyId}"}]}}`);
        employeesAndManagers = data;

        commit('SET_EMPLOYEES_AND_MANAGERS', employeesAndManagers);

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_OVERALL_STATS: async ({state, commit}, {from, to}) => {
      try {
        const fromDateString = from ? JSON.stringify(from).replace('"', '').slice(0, 10) : '';
        const toDateString = to ? JSON.stringify(to).replace('"', '').slice(0, 10) : '';
        const {data} = await Vue.http.get(`/statistics/get-overall-stats?from=${from ? fromDateString : ''}&to=${to ? toDateString : new Date()}`);
        return state.statisticData = data
        // CYB-3111 waiting backend part, should return dates for each user
      } catch (error) {
        const {message} = getValue(error, 'response.data.error', error);
        return {success: false, message};
      }
    },
    ADD_EMPLOYER: async ({state, commit}) => {
      try {
        const id = state.account.company.id;
        if (state.account.user.role === 'employee') {
          return
        }
        // add owner to the array of users
        const {data: owner} = await Vue.http.get(`/company/${id}/owner`);
        commit('ADD_OWNER', owner);

        return {success: true};
      } catch (error) {
        console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_ADMINS: async ({commit}) => {
      try {
        const {data: admins} = await Vue.http.get('/appuser');

        commit('SET_USERS', admins);

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_USER_DETAILS: async ({state, commit}, userId) => {
      try {
        const {data: employerData} = await Vue.http.get(`/employee/${userId}`);
        const {data: company} = await Vue.http.get(`/employee/${userId}/ownsOneCompany`);
        commit('SET_USER_DETAILS', {
          ...employerData,
          company,
        });

        return {success: true};
      } catch (error) {
          // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SEND_INVITATION_TO_USER: async ({state, commit, dispatch}, {firstname, lastname, email, role, assignedCourseList}) => {
      try {
        const {
          data: {
            employeeId,
          }
        } = await Vue.http.post('/employee/invite-employee', {
          firstname,
          lastname,
          email,
          isManager: role === 'manager',
          assignedCourseList
        });

        if (!employeeId) {
          return {
            success: false,
            message: i18n.t('users.email_already_exists'),
          };
        }

        commit('SAVE_INVITED_USER', {
          id: employeeId,
          firstname,
          lastname,
          email,
          role,
        });
        commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    CREATE_NEW_ADMIN: async ({state, commit, dispatch}, adminData) => { //
      try {
        const {data: createdAdmin} = await Vue.http.post('/employee/create-admin', adminData);
        if (!createdAdmin.created) {
          return {
            success: false,
            message: i18n.t('users.email_already_exists'),
          };
        }

        // commit('SAVE_INVITED_USER', admin);
        // commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_USER_DATA: async ({state, commit}, {id, firstname, lastname, role, password}) => {
      const {id: companyId} = state.account.company;

      try {
        const {data: updatedUser} = await Vue.http.put(
          `/company/${companyId}/employees/${id}`,
          {firstname, lastname, password: password || undefined},
        );

        if (updatedUser.role !== role) {
          await Vue.http.post(
            `/employee/toggle-role/${id}`,
            {expectedRole: role},
          );

          updatedUser.role = role;
        }

        commit('UPDATE_USER', updatedUser);
        commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    DELETE_USER: async ({state, commit}, userId) => {
      const {id: companyId} = state.account.company;

      try {
        await Vue.http.delete(`/company/${companyId}/employees/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    DELETE_ADMIN: async ({state, commit}, userId) => {
      try {
        await Vue.http.delete(`/appuser/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    DELETE_EMPLOYER: async ({state, commit}, userId) => {
      try {
        await Vue.http.post(`/employee/delete-employer-completely/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    RESET_COMPANY: async ({state, commit}, companyId) => {
      try {
        await Vue.http.post(`/progress/reset-company-progress`, {companyId});

        commit('CLEAR_SELECTED_USER_TO_RESET_COMPANY');

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_ACCOUNT_PASSWORD: async ({commit}, passwordData) => {
      try {
        await Vue.http.post('/employee/change-password', passwordData);

        return {success: true};
      } catch (error) {
        // console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_EMPLOYER_PASSWORD: async ({state, commit}, {employerId, password}) => {
      try {
        const {data} = await Vue.http.patch(`/employee/${employerId}`, {password});

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SET_BLOCK_STATE_FOR_EMPLOYER: async ({state, commit}, {employerId, block}) => {
      try {
        const {data} = await Vue.http.post(`/employee/block-account/${employerId}`, {block});

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_ACCOUNT_EMAIL: async ({state, commit}, newUserEmail) => {
      const {userId} = state.session;

      try {
        const {data} = await Vue.http.patch(`/employee/${userId}`, newUserEmail);

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SET_ACCOUNT_DATA: async ({state, commit}, newUserPath) => {
      const {userId} = state.session;

      try {
        const {data} = await Vue.http.patch(`/employee/${userId}`, newUserPath);

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SET_NEW_EMPLOYER: async ({state, commit}, {employeeId, role}) => {
      // where :employeeId is an ObjectId of the employee (from the same company) to be set as `employer`
      // and :role is a role name to be assigned to the ex-employer. Only (`employee` or `manager`) is allowed
      // no other option will be accepted.
      try {
        await Vue.http.post(`/employee/set-employer/${employeeId}/?role=${role}`)

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    CHANGE_TEMPORARY_PASSWORD: async ({state, commit}, newUserPassword) => {
      const {userId} = state.session;

      try {
        const {data} = await Vue.http.patch(`/employee/${userId}`, {
          password: newUserPassword,
          isFirstLogin: false,
        });

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    RESET_PASSWORD: async ({state, commit}, newPassword) => {
      try {
        await Vue.http.post('/employee/reset-password', {newPassword});

        commit('CLEAR_SESSION_DATA');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    RESET_PASSWORD_FOR_ADMIN: async ({state, commit}, newPassword) => {
      try {
        await Vue.http.post('/appuser/reset-password', {newPassword});

        commit('CLEAR_SESSION_DATA');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SET_ACCOUNT_COMPANY_DATA: async ({state, commit}, newCompanyPath) => {
      const {id} = state.account.company;

      try {
        const {data} = await Vue.http.patch(`/company/${id}`, newCompanyPath);

        commit('UPDATE_COMPANY_ACCOUNT_DATA', data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_EMPLOYER_DATA: async ({state, commit}, {patch, userId}) => {

      try {
        const {data: userData} = await Vue.http.patch(`/employee/${userId}`, patch);

        commit('SET_USER_DETAILS', {
          ...state.selectedUser,
          ...userData,
        });

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    UPDATE_EMPLOYER_COMPANY_DATA: async ({state, commit}, {patch, companyId}) => {
      try {
        const {data: company} = await Vue.http.patch(`/company/${companyId}`, patch);

        commit('SET_USER_DETAILS', {
          ...state.selectedUser,
          company,
        });

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COURSES: async ({commit}) => {
      // TODO: update after server side will be ready (logic of assigning can be different)
      try {
        const {data} = await new Promise(ok => {
          // Emulate server response
          setTimeout(ok, 150, {
            data: [
              {
                id: '1',
                slug: 'social-engineering',
                image: require('@/assets/images/social-engineering.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.social_engineering',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '2',
                slug: 'password-security',
                image: require('@/assets/images/password-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.password_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '3',
                slug: 'email-security',
                image: require('@/assets/images/email-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.email_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '4',
                slug: 'social-media',
                image: require('@/assets/images/social-media.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.social_media',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '5',
                slug: 'byod',
                image: require('@/assets/images/byod.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.byod',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '6',
                slug: 'connection-security',
                image: require('@/assets/images/connection-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.connection_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true
              },
              {
                id: '7',
                slug: 'physical-security',
                image: require('@/assets/images/physical-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.physical_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '8',
                slug: 'patchmanagement',
                image: require('@/assets/images/patchmanagement.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.patchmanagement',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '9',
                slug: '5-golden-rules',
                image: require('@/assets/images/5-golden-rules.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.5_golden_rules',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '10',
                slug: 'cyber-emergency',
                image: require('@/assets/images/cyber-notfall.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.cyber_emergency',
                employees: [],
                active: true,
              },
              {
                id: '11',
                slug: 'malware',
                image: require('@/assets/images/malware-image.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.malware',
                employees: [],
                active: true,
              },
              {
                id: '12',
                slug: 'remote-work',
                image: require('@/assets/images/remote-work.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.remote',
                employees: [],
                active: true,
              }
            ]
          });
        });

        commit('SET_COURSES', data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COMPANY_PROGRESS: async ({commit}) => {
      try {
        const {data: companyProgress} = await Vue.http.get('/progress/get-company-progress');

        commit('SET_COMPANY_PROGRESS', companyProgress);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COMPANY_STATISTIC: async ({commit}) => {
      try {
        const {data: companyStatistic} = await Vue.http.get('/statistics/get-company-stats');

        commit('SET_COMPANY_STATISTIC', companyStatistic);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COURSE_DATA: async ({commit}, courseSlug) => {
      try {
        const response = await Vue.http.get(`/trainings/get-training/${courseSlug}?lang=${i18n.locale}`);

        commit('SELECT_COURSE', response.data);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COURSE_PROGRESSES: async ({commit}) => {
      try {
        const {data: courseProgresses} = await Vue.http.get('/progress/get-progress');
        //this only applies if there are new courses added
        // for some reason employers are excerpted from having new progress objects
        // so we need to create them for every new training course added
        // without that they wont show in the list
        // this happens only once and for the already existing account who are employers.
        // if(getters.isEmployer) {

          // await dispatch('GET_COURSES');
          // const courses = getters.courses;

          // if (courses.length !== courseProgresses.length) {
          //   // console.log('CHECK inconsistent');
          //   const updatedProgresses = await Promise.all(
          //     courses.map( async course => {
          //       let match = courseProgresses.find(progress => course.slug === progress.trainingSlug)
          //       if (!match) {
          //         // console.log('MISSING: ', course.slug)
          //         await dispatch('CREATE_NEW_PROGRESS', course.slug);
          //         const {data: newprogress} = await Vue.http.get('/progress/get-single-progress/' + course.slug);
          //         // console.log('res', newprogress)
          //         return newprogress
          //       }
          //       return match
          //   }))

          //   commit('SET_COURSE_PROGRESSES', updatedProgresses);
          //   return { success:true }
          // }
        // }
        commit('SET_COURSE_PROGRESSES', courseProgresses);

        return {success: true};
      } catch (error) {
        console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COURSE_PROGRESS: async ({commit}, courseSlug) => {
      try {
        const {data: courseProgress} = await Vue.http.get('/progress/get-single-progress/' + courseSlug);

        commit('SET_COURSE_PROGRESS', courseProgress);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    SAVE_PROGRESS: async ({commit}, updatedProgress) => {
      try {
        await Vue.http.post('/progress/save-progress', updatedProgress);

        commit('UPDATE_COURSE_PROGRESS', updatedProgress);

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    // this is currently unused
    CREATE_NEW_PROGRESS: async ({commit}, slug) => {

      if (!slug) {
        console.error('missung slug');
        return
      }

      try {
        await Vue.http.post('/progress/create-progress', {trainingSlug: slug});
        return {success: true};
      } catch (error) {
        console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },

    UPLOAD_FILE: async ({commit}, formData) => {
      try {
        const {data} = await Vue.http.post('/pdf/upload-file', formData);

        return {success: data.uploaded};
      } catch (error) {
        console.error(error);

        const {message} = getValue(error, 'response.data.error', error);
        return {success: false, message};
      }
    },
    UPLOAD_LIST: async ({commit}, {formData, companyId}) => {

      try {
        const {data} = await Vue.http.post('/employee/upload-employee-list', formData);

        return {success: data};
      } catch (error) {
        console.error(error);

        const {message} = getValue(error, 'response.data.error', error);
        return {success: false, message};
      }
    },
    ASSIGN_ALL_EMPLOYEES_TO_THE_COURSE: async ({dispatch}, forCourse) => {
      try {
        await Vue.http.post('/trainings/assign-to-all', forCourse);

        await dispatch('GET_COMPANY_PROGRESS');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    REVOKE_ALL_EMPLOYEES_FROM_THE_COURSE: async ({dispatch}, fromCourse) => {
      try {
        await Vue.http.post('/trainings/revoke-for-all', fromCourse);

        await dispatch('GET_COMPANY_PROGRESS');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    ASSIGN_EMPLOYEE_TO_THE_COURSE: async ({dispatch}, {trainingSlug, ownerId}) => {
      try {
        await Vue.http.post('/trainings/assign', {trainingSlug, ownerId});

        await dispatch('GET_COMPANY_PROGRESS');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    REVOKE_EMPLOYEE_FROM_THE_COURSE: async ({dispatch}, {trainingSlug, ownerId}) => {
      try {
        await Vue.http.post('/trainings/revoke', {trainingSlug, ownerId});

        await dispatch('GET_COMPANY_PROGRESS');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    LOG_IN: async ({commit, dispatch}, {email, password}) => {
      try {
        const {
          data: {
            id: token,
            userId,
            ttl,
            created,
            lang = 'en',
          }
        } = await Vue.http.post('/employee/login', {email, password});

        commit('SET_SESSION_DATA', {
          token,
          userId,
          ttl,
          created,
          lang
        });

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    LOG_IN_ADMIN: async ({commit}, {email, password}) => {
      try {
        const {
          data: {
            id: token,
            userId,
            ttl,
            created,
            role,
          }
        } = await Vue.http.post('/appuser/login', {email, password});

        commit('SET_SESSION_DATA', {
          token,
          userId,
          ttl,
          created,
          role,
        });

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    LOG_OUT: async ({commit}) => {
      try {
        await Vue.http.post('/employee/logout');

        return {success: true};
      } catch (error) {
        console.error('catch', error)
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      } finally {
        commit('CLEAR_SESSION_DATA');
        commit('SET_AVV_BLOCK', false);
        commit('CLEAR_ACCOUNT_DATA');
        router.push('/')
      }
    },
    LOG_OUT_ADMIN: async ({commit}) => {
      try {
        await Vue.http.post('/appuser/logout');

        return {success: true};
      } catch (error) {
        // console.error(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      } finally {
        commit('CLEAR_SESSION_DATA');
        commit('CLEAR_ACCOUNT_DATA');
      }
    },
    CHANGE_LANGUAGE: async ({state, commit, dispatch }, lang) => {

      const userLang = state.account.user.lang ? state.account.user.lang : 'de';
      if ( userLang !== lang ) {
        const {userId} = state.session;
        await Vue.http.patch(`/employee/${userId}`, { lang: lang });
      }

      await loadLanguageAsync(lang);
      commit('SET_LANGUAGE', lang);

      const match = router.currentRoute.matched.find(el => {
        return el.name === 'training' || el.name === 'trainings'
      })
      // UPDATE COURSES IF WE ARE CURRENTLY IN THEM
      if (match) {
        await Promise.all([
            dispatch('GET_COURSE_DATA', router.currentRoute.params.slug),
            dispatch('GET_COURSE_PROGRESS', router.currentRoute.params.slug)
          ]);
      }
      return {success: true, message: 'success' };
    },
    SET_NOTIFICATIONS({state, commit}) {
      const isAvvNotification =
        (new Date(state.account.user.createdAt) < state.avvRegCheckboxAdded) &&
        (state.account.user.role === 'employer') &&
        (!state.account.user.orderProcessingAccepted)
      const notificationsList = []
      isAvvNotification ? notificationsList.push({name: 'Avv'}) : ''
      commit('ADD_NOTIFICATIONS', notificationsList)
    },
  }
});
