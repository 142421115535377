<template>
  <div class="auth-page__holder">
    <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link class="main-nav__logo-holder-mobile"
                       area-label="logo"
                       to="/"></router-link>
        </div>
      </div>
    </header>
    <div class="auth-page__logo">
      <img class="img-fluid"
           alt="Allianz Logo"
           :src="require('@/assets/custom/allianz-logo-white.svg')">
    </div>
    <div class="auth-page__form">
      <h1 class="auth-page__title">{{ title }}</h1>
      <p v-if="!emailSent" class="auth-page__sub-title">{{ $t('reset_password.subtitle') }}</p>
      <p v-else class="auth-page__sub-title">{{ $t('reset_password.email_sent_success') }}</p>
      <form action="#"
            v-if="!emailSent"
            novalidate
            @submit.prevent="sendRecoveryEmail">
        <div class="auth-page__row"
             :class="{ 'has-error': $v.form.email.$error }">
          <label class="input__holder">
            <input type="email"
                   v-model="form.email"
                   name="email"
                   class="input">
            <span class="input__label"
                  :class="{ 'input__label--active': !!form.email }">{{ $t('common.email') }}</span>
          </label>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <button class="btn btn--default btn--block btn--uppercase"
                    type="button"
                    @click="() => $router.back()">{{ $t('common.back') }}</button>
          </div>
          <div class="col-xs-6">
            <button class="btn btn--primary btn--block btn--uppercase"
                    :class="{ 'btn--pending': emailSentPending }"
                    type="submit"
                    :disabled="$v.form.$error || emailSentPending">
              <icon name="sync"
                    class="btn__pending-icom"
                    color="white"
                    :rotate="emailSentPending"></icon>
              {{ $t('common.next') }}
            </button>
          </div>
        </div>
      </form>
      <button v-else
              class="btn btn--primary btn--block btn--uppercase"
              @click="() => $router.push('/admin/login')"
              type="submit"
              :disabled="$v.form.$error">{{ $t('reset_password.back_to_sign_in') }}</button>
    </div>
    <CustomToasted ref="customToasted" class="custom-toasted-container"></CustomToasted>
  </div>
</template>

<script>
  import { required, email } from "vuelidate/lib/validators";
  import Icon from '@/components/icon/Icon.vue';
  import Logo from '@/components/logo/Logo.vue';
  import CustomToasted from '@/components/customToasted';

  export default {
    name: 'reset-password',
    components: {
      Logo,
      Icon,
      CustomToasted
    },
    data() {
      return {
        form: {
          email: '',
        },
        title: this.$t('page.reset_password_title'),
        emailSent: false,
        emailSentPending: false
      };
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        email: { required, email },
      }
    },
    methods: {
      async sendRecoveryEmail() {
        this.$v.form.$touch();

        if (this.$v.form.$error || this.emailSentPending) return;
        this.$v.form.$reset();
        this.emailSentPending = true;

        const { success, message } = await this.$store.dispatch('PASSWORD_RESET_REQUEST_FOR_ADMIN', this.form.email);
        this.emailSentPending = false;
        this.emailSent = success;

        if (success) {
          this.$refs.customToasted.show({
              message: this.$t('reset_password.link_sent_successfully'),
              type: 'success',
              duration: 3000
            })
        } else {
          this.$refs.customToasted.show({
            message: this.$t('reset_password.error'),
            type: 'error',
            duration: 3000 
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.custom-toasted-container {
  margin-top: 2rem;
}
</style>
