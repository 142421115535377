<template>
    <div v-if="isActionsBlocked && !isLocationException" class="overlay">
        <span> {{ $t('common.is_actions_blocked') }} </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            lang: 'en'
        }
    },
    computed: {
        ...mapGetters([
            'isActionsBlocked',
            'accountCompany'
        ]),
        isLocationException() {
            return this.$route.name === 'account-settings' || this.$route.name === 'login';
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
 
.overlay {
    position: fixed;
    left: 0px;
    z-index: 100;
    background-color: rgba(14, 14, 14, 0.786);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    
    span {
      max-width: 70%;
      color: #fff;
      font-size: large;
      padding-left: 270px;

      @media screen and (max-width: 991px) {
        color: #fff;
        padding-left: 0px;

      }
    }
  }
</style>
  