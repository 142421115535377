<template>
  <v-touch tag="div"
           @swipeleft="closeMenu"
           @swiperight="openMenu"
           :swipe-options="{ direction: 'horizontal' }"
           class="main-nav"
           v-if="!hideNav"
           v-click-outside="closeMenu"
           :class="{ 'main-nav--active': mobileMenuOpen }">
    <div class="main-nav__mobile-header">
      <router-link class="main-nav__logo-holder-mobile"
        area-label="logo"
        to="/"
      >
          <logo color="dark"/>
      </router-link>
      <div class="main-nav__menu-burger"
           @click="toggleMenu">
        <icon :name="burgerName"
              color="dark"></icon>
      </div>
    </div>
    <div class="main-nav__holder">
      <router-link class="main-nav__logo-holder"
                   area-label="logo"
                   to="/"><logo/></router-link>
      <v-touch tag="nav"
               @swipeleft="closeMenu"
               :swipe-options="{ direction: 'horizontal' }"
               class="main-nav__content">
        <LangSwitch :selected='i18' v-if="!isAdmin"/>
        <h2 class="main-nav__title"
            v-if="isAdmin">E-LEARNING PORTAL</h2>
        <h2 class="main-nav__title"
            v-if="isEmployer || isManager">{{ $t('menu.employees') }}</h2>
        <ul class="main-nav__item-holder"
            v-if="isAdmin || isEmployer || isManager">
          <li class="main-nav__item"
              v-if="isEmployer || isManager">
            <router-link to="/users" class="main-nav__link" 
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
              <icon name="user"></icon>{{ $t('menu.manage_employees') }}
            </router-link>
          </li>
          <li class="main-nav__item"
              v-if="isAdmin">
            <router-link to="/admin/policyholders"
                         class="main-nav__link">
              <icon name="user"></icon> {{ $t('menu.manage_customers') }}
            </router-link>
          </li>

          <li class="main-nav__item"
              v-if="isAdmin">
            <router-link to="/admin/upload"
                         class="main-nav__link">
              <icon name="user"></icon> {{ $t('menu.userslist_upload') }}
            </router-link>
          </li>

          <li class="main-nav__item"
              v-if="isAdmin">
            <router-link to="/admin/admins"
                         class="main-nav__link">
              <icon name="user"></icon> {{ $t('menu.manage_admins') }}
            </router-link>
          </li>
          <li class="main-nav__item"
              v-if="isEmployer || isManager">
            <router-link to="/course-settings" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="settings"></icon> {{ $t('menu.course-settings') }}
            </router-link>
          </li>
          <li class="main-nav__item"
              v-if="isEmployer || isManager">
            <router-link to="/dashboard" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="dashboard"></icon> {{ $t('menu.dashboard') }}
            </router-link>
          </li>
          <li class="main-nav__item"
              v-if="isEmployer || isManager">
            <router-link to="/proofOfInsurance" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="list-alt"></icon> {{ $t('menu.proofOfInsurance') }}
            </router-link>
          </li>
          <li class="main-nav__item"
              v-if="isAdmin">
            <router-link to="/admin/statistics" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="dashboard"></icon> Statistik
              <!-- {{ $t('menu.statistics') }} -->
            </router-link>
          </li>
        </ul>
        <h2 class="main-nav__title"
          v-if="!isAdmin">{{ $t('menu.training') }}</h2>
        <ul class="main-nav__item-holder"
          v-if="!isAdmin">
          <li class="main-nav__item">
            <router-link to="/trainings" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="training"></icon> {{ $t('menu.my_trainings') }}
            </router-link>
          </li>
          <li class="main-nav__item">
            <router-link to="/personalCertificate" class="main-nav__link"
              :is="isActionsBlocked ? 'span' : 'router-link'"
              :class="{disabled: isActionsBlocked}">
                <icon name="certificate"></icon> {{ $t('menu.personalCertificate') }}
            </router-link>
          </li>
        </ul>
        <h2 class="main-nav__title">{{ $t('menu.my_account') }}</h2>
        <ul class="main-nav__item-holder">
          <li class="main-nav__item"
              v-if="!isAdmin">
            <router-link to="/account-settings"
                         class="main-nav__link">
              <icon name="settings"></icon> {{ $t('menu.settings') }}
            </router-link>
          </li>
          <li class="main-nav__item" v-if="isEmployer">
              <span class="main-nav__link" @click="openAvv">
                <icon name="article"></icon> AVV
              </span>
          </li>
          <li class="main-nav__item">
            <router-link to="/logout"
                         class="main-nav__link">
              <icon name="logout"></icon> {{ $t('menu.log_out') }}
            </router-link>
          </li>
        </ul>
      </v-touch>
      <div class="main-nav__actions">
        <div class="main-nav__actions-holder">
          <router-link to="/impressum"
                       title="Impressum"
                       class="main-nav__action-link">
            <icon name="contact-phone"></icon>
          </router-link>
          <a :title="$t('common.email')"
             rel="nofollow noopener"
             href="mailto:training@cyberdirekt.de"
             class="main-nav__action-link">
            <icon name="email"></icon>
          </a>
          <a :title="$t('common.help')"
             href="#"
             @click="showVideo()"
             rel="nofollow noopener"
             v-if="isManager || isEmployer"
             class="main-nav__action-link">
            <icon name="help"></icon>
          </a>
        </div>
      </div>
    </div>
  </v-touch>
</template>

<script>
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import { mapActions, mapGetters } from 'vuex';
  import eventBus from '@/components/EventBus.js';
  import { loadLanguageAsync } from '@/i18n'
  import i18n from '@/i18n';
  import LangSwitch from '@/components/langSwitch/LangSwitch.vue';


  export default {
    name: 'main-nav',
    components: {
      Logo,
      Icon,
      LangSwitch
    },
    data() {
      return {
        mobileMenuOpen: false
      }
    },
    computed: {
      burgerName() {
        return this.mobileMenuOpen ? 'close' : 'menu';
      },
      i18(){
          return this.$i18n.locale
      },
      hideNav() {
        return !!this.$route.meta.hideNavigation;
      },
      ...mapGetters([
        'isAdmin',
        'isEmployer',
        'isManager',
        'isEmployee',
        'shouldShowHelpVideo',
        'isActionsBlocked'
      ]),
    },
    watch:{
      $route(to, from){
        this.mobileMenuOpen = false;
      }
    },
    methods: {
      showVideo() {
        eventBus.$emit('showVideo');
      },
      toggleMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      openMenu() {
        this.mobileMenuOpen = true;
      },
      closeMenu() {
        this.mobileMenuOpen = false;
      },
      openAvv() {
        this.$store.commit('OPEN_AVV_MODAL')
      },
    },
  }
</script>

<style lang="scss">
@import "@/styles/variables.scss";

$main-nav-width: 270px;
$mobile-header-height: 56px;

.main-nav {
  & + div {
    padding-left: $main-nav-width;
  }

  &__holder {
    background: $allianz-side-bg;
    // background: linear-gradient(0deg, $allianz-side-bg 10%, rgba(9, 9, 121, 0.799) 100%);
    width: $main-nav-width;
    position: fixed;
    z-index: $z-index-main-nav;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &__mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: $z-index-main-nav;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px;
    text-align: center;
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  }

  &__logo-holder {
    display: block;
    // background: $allianz-side-bg;
    line-height: 0;
    padding: 37px 30px;
    outline: none;
  }

  &__logo-holder-mobile {
    display: inline-block;
    line-height: 0;
    padding: 5px 15px;
    outline: none;
    max-width: 230px;
  }

  &__menu-burger {
    padding: 5px 15px;
  }

  &__content {
    position: absolute;
    top: 117px;
    left: 0;
    right: 0;
    bottom: 77px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__title {
    color: $menu-titles;
    text-align: left;
    padding: 5px 30px;
    margin: 0;
    font-size: 0.785rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  &__item-holder {
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__item {
    width: 250px;

    & + & {
      margin-top: 1px;
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $menu-items-text;
    padding: 8px 10px 8px 26px;
    border-radius: 0 20px 20px 0;
    line-height: 1.5rem;
    border-style: solid;
    border-width: 0 0 0 4px;
    border-color: transparent;
    outline: none;

    background-color: none;
    transition: background-color .4s ease-out, border-color 1s ease;
    

    .icon {
      fill: $menu-items-text;
      margin-right: 15px;
      transition: transform .3s ease-out;

    }

    &:hover,
    &:focus,
    &.router-link-active {
      color: #fff;
      border-color: $secondary;

      .icon {
        fill: #fff;
        transform: scale(1.1)
      }
    }

    &:hover {
      background-color: darken($allianz-side-bg, 5%);
      border-color: $allianz-light-blue;
    }
    
    &.router-link-active {
      background-color:  darken($allianz-side-bg, 10%);
    }

    &.router-link-active {
      cursor: default;
    }
    &.disabled {
      opacity: 0.5;
    }
  }

  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__actions-holder {
    display: flex;
    flex: 1;
    justify-content: stretch;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__action-link {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 62px;
    outline: none;
    // background: $allianz-side-bg;;
    // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    border: 1px solid transparent;

    .icon {
      fill: $menu-items-text;
    }

    & + & {
      // border-left-color: $dark-blue-3;
    }

    &:hover,
    &:focus {
      background: darken($allianz-side-bg, 10%);

      .icon {
        fill: #fff;
      }
    }

    &[disabled] {
      .icon {
        fill: $middle-grey;
        pointer-events: none;
        cursor: pointer;
      }

      &:hover {
        background: $dark-blue-1;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &__holder {
      transition: transform 0.3s, opacity 0.3s;
      transform: translate(-100%, 0);
      opacity: 0;
    }

    &__mobile-header {
      display: flex;
    }

    &__content {
      top: $mobile-header-height + 15px;
    }

    &__logo-holder {
      padding: 10px 20px;
      height: $mobile-header-height;
    }

    & + div {
      padding-top: $mobile-header-height;
      transition: padding-left 0.3s;
      padding-left: 0;
    }

    &--active {
      .main-nav__holder {
        transform: translate(0, 0);
        opacity: 1;
      }

      & + div {
        padding-left: $main-nav-width;
      }
    }

    &__title {
      padding: 5px 20px;
    }

    &__link {
      padding-left: 16px;
    }
  }

  @media screen and (max-width: $main-nav-width + 480px) {
    &--active {
      & + div {
        padding-left: 0;
      }
    }
  }
}
</style>
