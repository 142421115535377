<template>
  <div>
    <img v-if="color === 'white'" class="img-fluid"
      alt="Allianz Logo"
      :src="require('@/assets/custom/allianz-logo-white.svg')"
    />
    <img v-else class="img-fluid"
      alt="Allianz Logo"
      :src="require('@/assets/custom/allianz-logo.svg')"
    />
  </div>
</template>

<script>
export default {
  name: 'logo',
  props: {
    color: {
      type:String,
      default: 'white',
    },
  },
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.logo {
  width: 175px;
  height: 36px;
  
  &--white {
    fill: #fff;
  }
  
  &--dark {
    fill: $dark-blue;
  }
  
  &__part-1 {
    fill: $secondary;
  }
  
  &__part-2 {
    fill: $primary;
  }
}
</style>
