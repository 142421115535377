<template>
  <div id="app">
    <notifications></notifications>
    <main-nav></main-nav>
    <div>
      <is-actions-blocked/>
      <router-view />
    </div>
    <video-modal ></video-modal>
    <avv-modal></avv-modal>
  </div>
</template>

<script>
  import IsActionsBlocked from './components/isActionsBlocked/IsActionsBlocked.vue'
  import MainNav from '@/containers/main-nav/MainNav.vue';
  import VideoModal from '@/containers/video-modal/VideoModal.vue';
  import { COMPANY_NAME } from '@/constants';
  import AvvModal from '@/containers/users-modals/AVVModal.vue';
  import Notifications from '@/components/notifications/Notifications.vue';

  export default {
    name: 'App',
    components: {
      IsActionsBlocked,
      MainNav,
      VideoModal,
      AvvModal,
      Notifications
    },
    metaInfo: {
      title: 'Welcome',
      titleTemplate: `%s | ${COMPANY_NAME}`,
      link: [
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: require('@/assets/images/cyberdiekt-favicon.png'),
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        },
      ],
    }
  }
</script>

<style lang="scss">
  #app {
    min-height: 87vh;
  }
</style>
