<template>
  <modal
    v-if="isAvvModal"
    :closable="isClosable"
    @close="closeModal"
    container-size="lg"
    html-class="avv-modal">
    <div slot="header">
      <h1
        slot="header"
        class="MsoNormal-bold"
        align="center"
        style="text-align: center; line-height: normal; font-weight: bold;"
      >
        Auftragsdatenverarbeitungsvertrag CyberDirekt GmbH Version 5.40
      </h1>
    </div>
    <div slot="body">
      <BodyAvv />
    </div>
    <div slot="footer">
      <div class="submition-row">
        <div class="checkbox">
          <div>
            <input
              v-model="orderProcessingAccepted"
              class="checkbox__input"
              type="checkbox"
              id="checkbox-avv"
              name="checkbox-avv"
              @change="submitAvv"
              :disabled="orderProcessingAccepted"
            />
            <label for="checkbox-avv"
                     class="checkbox__label"
              >Ich akzeptiere den Auftragsverarbeitungsvertrag.</label
            >
          </div>
          <p v-if="acceptanceDate" class="avv-subnote">{{ $t('common.accepted')}} {{ acceptanceDate | formatDate}} </p>
        </div>
        <div>
          <button class="btn btn--default btns" v-if="isClosable" @click="closeModal">
            {{$t('common.close')}}
          </button>
          <button class="btn btn--default btns" v-if="!orderProcessingAccepted" @click="cancel">
            {{$t('common.cancel')}}
          </button>
          <button class="btn btn--primary btns" @click="dwnld">
            Jetzt herunterladen
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue'
import BodyAvv from '@/components/avv/BodyAvv.vue'
import moment from 'moment'

export default {
  name: 'AVVModal',
  components: {
    Modal,
    BodyAvv
  },
  data() {
    return {
      orderProcessingAccepted: this.$store.state.account.user.orderProcessingAccepted
    }
  },
  computed: {
    isAvvModal() {
      return this.$store.state.account.isAvvModal
    },
    acceptanceDate() {
      return this.$store.state.account.user.orderProcessingAcceptedAt
    },
    isClosable() {
      return this.orderProcessingAccepted ? true : false
    }
  },
  watch: {
    acceptanceDate() {
      // update agreement when data are loaded.
      this.orderProcessingAccepted = this.$store.state.account.user.orderProcessingAccepted;
    }
  },
  filters: {
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  },
  methods: {
    submitAvv() {
      this.$store
        .dispatch('SET_ACCOUNT_DATA', {
          orderProcessingAccepted: this.orderProcessingAccepted,
          orderProcessingAcceptedAt: new Date()
        })
        .then(() => {
          this.$store.commit('CLOSE_AVV_MODAL')          
          this.$store.dispatch('SET_NOTIFICATIONS')
        })
      return
    },
    cancel() {
      this.$store.commit('CLOSE_AVV_MODAL')  
      this.$store.dispatch('LOG_OUT')
    },
    closeModal() {
      if (this.isClosable) {
        this.$store.commit('CLOSE_AVV_MODAL')  
      }
    },
    dwnld() {
      const url = 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/AVV_Cyberdirekt_Gmbh_V_5_40.pdf'
      const label = 'AVV_CyberDirekt_GmbH_V_5_40'
      fetch(url, { method: 'GET' })
          .then(res => {
            return res.blob();
          })
          .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = label;
            document.body.appendChild(a);
            a.click();
            setTimeout(
              _ => { window.URL.revokeObjectURL(url); },
              60000);
            a.remove();
          })
          .catch(err => {
            console.error('err: ', err);
          })
      
    },
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

h1 {
  color: #000;
  word-break: break-word;
}
.btns {
  margin-left: 5px;
  margin-right: 5px;
}
.avv-subnote {
  margin-top: 5px;
  font-size: 0.9em;
}
.submition-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 0.75rem;
  margin-top: 2rem;
  border-top: 1px solid $middle-grey;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    align-items: start;
  }
}

.avv-modal {
 .modal-wrapper {
    height: 100vh;
    overflow: auto;
  }

 .modal-container {
    position: static;
    overflow: auto;
    height: 90vh;
    padding-top: 65px;
  }

  .modal-cancel svg {
    fill: #fff;
  }
}
</style>
