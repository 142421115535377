<template>
  <div v-if="session.userId && notifications && notifications.length" class="notifications-wrapper">
    <div class="notifications">
      <div class="container">
        <div v-for="(oneNotif, idx) in notifications" :key="`main-notification-${idx}`">
          <component :is="oneNotif.name"></component>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avv from "./AvvNotification.vue"

export default {
  components: {
    Avv
  },
  computed: {
    notifications() {
      return this.$store.state.account.notifications
    },
    session() {
      return this.$store.state.session
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.notifications {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  color: #fff; 
  background: #9da1a6;
  width: 100%;
  padding: 1rem 0;
}

.notifications-wrapper {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1195;
    background: rgba(0, 0, 0, 0.7);
  }
}

.text {
}

::v-deep .actions {
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
}
</style>
