const INSURANCE_NUMBER_BLACKLIST = [
  'AS-0000000000',
  'AS-1111111111',
  'AS-2222222222',
  'AS-3333333333',
  'AS-4444444444',
  'AS-5555555555',
  'AS-6666666666',
  'AS-7777777777',
  'AS-8888888888',
  'AS-9999999999',
  'AS-1234567890',
  'AS-1234567899',
  'AS-9876543210',
  'AS-9876543211',
];

export function isConfirmedToDelete(value) {
  if (!value) return true;

  return value === 'DELETE';
}

export function isConfirmedToReset(value) {
  if (!value) return true;

  return value === 'RESET';
}

export function isInsuranceNumberValid(value) {
  if (!value) return true;

  return /^AS-\d{10}$/.test(value) && INSURANCE_NUMBER_BLACKLIST.indexOf(value) === -1;
}
