<template>
    <div class="auth-page__holder">
        <header class="auth-page__header">
            <div class="container">
                <div class="flex flex--between flex--v-center">
                    <router-link class="main-nav__logo-holder-mobile"
                                 area-label="logo"
                                 to="/">
                        <!-- <logo color="dark"/> -->
                    </router-link>

                    <div>
                        <span class="mobile-hide">{{ $t('login.dont_have_an_account') }} </span>
                        <router-link to="/register"
                                     class="link">{{ $t('common.register') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </header>
        <div class="auth-page__logo">
            <img class="img-fluid"
                 alt="Allianz Logo"
                 :src="require('@/assets/custom/allianz-logo-white.svg')">
        </div>
        <div class="auth-page__form">
            <h1 class="auth-page__title">{{ title }}</h1>
            <form action="#"
                  novalidate
                  @submit.prevent="logIn">
                <div class="auth-page__row"
                     :class="{ 'has-error': $v.form.email.$error }">
                    <label class="input__holder">
                        <input type="email"
                               v-model="form.email"
                               name="email"
                               class="input">
                        <span class="input__label"
                              :class="{ 'input__label--active': !!form.email }">{{ $t('common.email') }}</span>
                    </label>
                </div>
                <div :class="{ 'has-error': $v.form.password.$error }">
                    <label class="input__holder">
                        <input type="password"
                               v-model="form.password"
                               name="password"
                               class="input">
                        <span class="input__label"
                              :class="{ 'input__label--active': !!form.password }">{{ $t('common.password') }}</span>
                    </label>
                </div>
                <div class="auth-page__forgot-password">
                    <router-link to="/reset-password"
                                 class="link"
                                 @click.prevent="showForgotForm">{{ $t('common.forgot_password') }}?
                    </router-link>
                </div>
                <div class="auth-page__buttons-holder">
                    <button class="btn btn--primary btn--lg btn--block btn--uppercase"
                            :class="{ 'btn--pending': logInPending }"
                            type="submit"
                            :disabled="$v.form.$error || logInPending">
                        <icon name="sync"
                              class="btn__pending-icom"
                              color="white"
                              :rotate="logInPending"></icon>
                        {{ $t('common.sign_in') }}
                    </button>
                </div>
                <div class="text--center">
                    {{ $t('login.dont_have_an_account') }}
                    <router-link to="/register"
                                 class="link">{{ $t('common.register') }}
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
  import {required} from "vuelidate/lib/validators";
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import {COMPANY_NAME} from '@/constants';

  export default {
    name: 'login',
    components: {
      Logo,
      Icon,
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        title: this.$t('page.login_title', { name: COMPANY_NAME }),
        logInPending: false,
      };
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        email: { required },
        password: { required },
      }
    },
    methods: {
      async logIn() {
        this.$v.form.$touch();

        if (this.$v.form.$error || this.logInPending) return;
        this.$v.form.$reset();
        this.logInPending = true;

        const { success } = await this.$store.dispatch('LOG_IN', this.form);
        this.logInPending = false;

        if (success) {
          if (this.$route.query.next) {
            this.$router.push(this.$route.query.next)
          } else {
            this.$router.push('/');
          }
        } else {
          this.$toasted.show(this.$t('login.failed'), { type: 'error' });
        }
      },
      showForgotForm() {

      }
    }
  }
</script>

<style lang="scss">
</style>
